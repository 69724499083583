export const env = Object.freeze({
  PROJECT_NAME: import.meta.env.PROJECT_NAME,
  VERSION: import.meta.env.VERSION,
  APP_ENV: import.meta.env.APP_ENV,
  IS_DEV: import.meta.env.APP_ENV !== 'production',
  IS_PROD: import.meta.env.APP_ENV === 'production',

  SITENAME: import.meta.env.SITENAME,
  FUNCTIONS_SECRET: import.meta.env.FUNCTIONS_SECRET,
  FRONT_URL: import.meta.env.FRONT_URL,
  API_URL: import.meta.env.API_URL,

  GOOGLE_MEASUREMENT_ID: import.meta.env.GOOGLE_MEASUREMENT_ID,
  GOOGLE_ANALYTICS_ID: import.meta.env.GOOGLE_ANALYTICS_ID,
  GOOGLE_OPTIMIZE_ID: import.meta.env.GOOGLE_OPTIMIZE_ID,
  GOOGLE_OAUTH_CLIENT_ID: import.meta.env.GOOGLE_OAUTH_CLIENT_ID,
  GOOGLE_TRANSLATE_KEY: import.meta.env.GOOGLE_TRANSLATE_KEY,

  SENTRY_DNS: import.meta.env.SENTRY_DNS,
  SENTRY_AUTH_TOKEN: import.meta.env.SENTRY_AUTH_TOKEN,
  SENTRY_ORG: import.meta.env.SENTRY_ORG,
  // SENTRY_PROJECT: import.meta.env.PROJECT_NAME,

  FACEBOOK_PIXEL_ID: import.meta.env.FACEBOOK_PIXEL_ID,
  FACEBOOK_TEST_CODE: import.meta.env.FACEBOOK_TEST_CODE,

  AMPLITUDE_API_KEY: import.meta.env.AMPLITUDE_API_KEY,

  HOTJAR_ID: import.meta.env.HOTJAR_ID,
  HOTJAR_VERSION: import.meta.env.HOTJAR_VERSION,
})
